const PAISES = [
    {
        id: 'ARGENTINA',
        name: 'Argentina'
    },
    {
        id: 'BOLIVIA',
        name: 'Bolivia'
    },
    {
        id: 'BRASIL',
        name: 'Brasil'
    },
    {
        id: 'COSTARICA',
        name: 'Costa Rica'
    },
    {
        id: 'EEUU',
        name: 'Estados Unidos'
    },
    {
        id: 'GUATEMALA',
        name: 'Guatemala'
    },
    {
        id: 'MEXICO',
        name: 'México'
    },
    {
        id: 'URUGUAY',
        name: 'Uruguay'
    },
    {
        id: 'PANAMA',
        name: 'Panamá'
    },
    {
        id: 'PARAGUAY',
        name: 'Paraguay'
    },
    {
        id: 'PERU',
        name: 'Peru'
    },
    {
        id: 'REPUBLICADOMINICANA',
        name: 'República Dominicana'
    }
]

export default PAISES