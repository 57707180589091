import React, { useState, useEffect } from 'react';

import Swal from 'sweetalert2'

import { IconContext } from "react-icons";
import { BiTrashAlt } from "react-icons/bi";
import { FiLogIn } from "react-icons/fi";
import { BsFileEarmarkArrowDown } from "react-icons/bs";
import Button from 'react-bootstrap/Button';

import { FaExclamation } from "react-icons/fa";

import empresasService from '../../../../services/Empresas';
import authService from '../../../../services/Auth';

import environment from '../../../../environment/environment';
import env from '../../../../environment/environment';

// COMPONENTS

// ITEM LIST HEADER
const EmpresaListItemHeader = () => {

  return (
    <div className="empresaListItem header">
      <div className="name"> Name </div>
      <div className="actions">
        <IconContext.Provider size={ 25 } value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
          <div>
            <BiTrashAlt />
          </div>
        </IconContext.Provider>
      </div>
    </div>
  )
}

// ITEM LIST DEL BODY
const EmpresaListItem = ( props ) => {

  return (
    <div className="empresaListItem">
      <div className="name"> { props.name } </div>
      <div className="actions">

      <div className="download_forms" onClick={ () => downloadForms( props.empresaID ) }>
          <IconContext.Provider size={ 25 } value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
            <div>
              <BsFileEarmarkArrowDown />
            </div>
          </IconContext.Provider>
          <form id="excelCompanyForms" method="post" action={ env.url + '/files/excel/company' } target="TheWindow">
            <input type="hidden" name="company" value="content" />
            <input type="hidden" name="token" value="content" />
          </form>
        </div>

        <div className="delete" onClick={ () => deleteEmpresa( props.empresaID, props.reloadEmpresas ) }>
          <IconContext.Provider size={ 25 } value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
            <div>
              <BiTrashAlt />
            </div>
          </IconContext.Provider>
        </div>

        <div className="login" onClick={ () => sudoEmpresa( props.empresaID ) }>
          <IconContext.Provider size={ 25 } value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
            <div>
              <FiLogIn />
            </div>
          </IconContext.Provider>
        </div>

      </div>
    </div>
  )
}

// LISTA COMPLETA DE USUARIOS
const EmpresasList = ( props ) => {

  // VARIABLES DE ESTADO DE PAGINACION Y FILTRO
  const [ filteredList, setFilteredList ] = useState( [] );

  // HOOK PARA ACTUALIZACION DE LISTA DE USUARIOS EN FUNCION DEL FILTRO
  useEffect( () => {
    const fullList = props.empresas.map( empresa => {

      const matchCountry = props.countryFiltered === 'ALL' ? true : ( empresa.pais === props.countryFiltered )

      if ( empresa.nombre.toUpperCase().includes( props.filterString.toUpperCase() ) && matchCountry ){
        return <EmpresaListItem key={ empresa._id } empresaID={ empresa._id } name={ empresa.nombre } reloadEmpresas={ props.reloadEmpresas } />
      }

    } ).filter( data => data )

    setFilteredList( fullList );

  }, [ props.filterString, props.countryFiltered ] )

  // RENDERIZO LA LISTA DE ZONAS CON LA PAGINACION
  if ( props.empresas.length ){
    return (
      <div className="empresasList">
        <div className="list">
          <EmpresaListItemHeader />
          { filteredList }
        </div>
      </div>
    )
  } else {
    return (
      <div className="noCompanies">
        <div className="logo">
          <IconContext.Provider value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
            <div>
              <FaExclamation />
            </div>
          </IconContext.Provider>
        </div>
        <div className="title"> ¡ Sin Empresas ! </div>
        <div className="message">
          <p> Todavía no creaste ninguna empresa. </p>
          <p> Podrás dar de alta todas las empresas en esta sección. </p>
        </div>
        <div className="action">
          <Button variant="success" onClick={ () => { document.querySelector( '.empresasPage .newEmpresa' ).click() } }> Agregar Empresa </Button>
        </div>
      </div>
    )
  }
}

// FUNCTIONS

const sudoEmpresa = ( empresaID ) => {
  authService.sudo({
    id: empresaID
  }).then( response => {
    window.location = environment.front_url + '/login/' + response.data.token
  })
}

// BAJA DE EMPRESA
const deleteEmpresa = ( empresaID, reloadEmpresas ) => {

  // DISPARO ALERT DE CONFIRMACION
  Swal.fire({
            title: 'Eliminar Empresa',
            text: 'Are you sure you want to delete this company?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes, delete it!',
            showLoaderOnConfirm: true,
            // AL CONFIRMAR LE PEGO A LA API DE DELETE /ZONES
            preConfirm: ( value ) => {
              return empresasService.delete( empresaID )
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            // AL FINALIZAR MUESTRO ALERT DE SUCCESS Y LE PEGO AL HANDLER DE RELOAD PAGE
            if ( result.value ) {
              Swal.fire(
                'Deleted!',
                'Company has been deleted successfully.',
                'success'
              )

              reloadEmpresas();
            }
          })
}

const downloadForms = empresa => {

  var f = document.getElementById('excelCompanyForms');
  f.token.value = authService.getToken();
  f.company.value = empresa;
  window.open('', 'TheWindow');
  f.submit();

}


export default EmpresasList
