const FUNCIONALIDADES = [
    {
        id: 11,
        code: 'USUARIO_ALTA'
    },
    {
        id: 12,
        code: 'USUARIO_BAJA'
    },
    {
        id: 13,
        code: 'USUARIO_LECTURA'
    },
    {
        id: 21,
        code: 'GRANJA_ALTA'
    },
    {
        id: 22,
        code: 'GRANJA_BAJA'
    },
    {
        id: 23,
        code: 'GRANJA_MODIFICACION'
    },
    {
        id: 24,
        code: 'GRANJA_LECTURA'
    },
    {
        id: 25,
        code: 'GRANJA_UNIR'
    },
    {
        id: 31,
        code: 'FORMULARIO_VIIP_ALTA'
    },
    {
        id: 32,
        code: 'FORMULARIO_VIIP_BAJA'
    },
    {
        id: 33,
        code: 'FORMULARIO_VIIP_MODIFICACION'
    },
    {
        id: 34,
        code: 'FORMULARIO_VIIP_LECTURA'
    },
    {
        id: 35,
        code: 'RESULTADOS_VIIP_LECTURA'
    },
    {
        id: 36,
        code: 'FORMULARIO_VIIP_RECALCULAR'
    },
    {
        id: 41,
        code: 'ZONA_ALTA'
    },
    {
        id: 42,
        code: 'ZONA_BAJA'
    },
    {
        id: 43,
        code: 'ZONA_MODIFICACION'
    },
    {
        id: 44,
        code: 'ZONA_LECTURA'
    },
    {
        id: 51,
        code: 'PRODUCTO_ALTA'
    },
    {
        id: 52,
        code: 'PRODUCTO_MODIFICACION'
    },
    {
        id: 53,
        code: 'PRODUCTO_BAJA'
    },
    {
        id: 54,
        code: 'PRODUCTO_LECTURA'
    },
    {
        id: 61,
        code: 'NOTIFICACIONES_ALTA'
    },
    {
        id: 62,
        code: 'NOTIFICACIONES_MODIFICACION'
    },
    {
        id: 63,
        code: 'NOTIFICACIONES_BAJA'
    },
    {
        id: 71,
        code: 'EMPRESA_ALTA'
    },
    {
        id: 72,
        code: 'EMPRESA_MODIFICACION'
    },
    {
        id: 73,
        code: 'EMPRESA_BAJA'
    },
    {
        id: 74,
        code: 'EMPRESA_LECTURA'
    },
    {
        id: 100,
        code: 'EXCEL_FORMULARIO_DESCARGA'
    },
    {
        id: 200,
        code: 'PERFIL_MODIFICACION'
    },
    {
        id: 201,
        code: 'CONTRASENA_MODIFICACION'
    },
    {
        id: 666,
        code: 'SUDO_EMPRESA'
    }
]

const SCOPES = [
    {
        id: 1,
        code: 'SELF'
    },
    {
        id: 2,
        code: 'COMPANY'
    },
    {
        id: 3,
        code: 'COUNTRY'
    },
    {
        id: 4,
        code: 'GLOBAL'
    },
    {
        id: 999,
        code: 'SUDO'
    }
]

export const getUserScopeIDFromCode = ( scopeCode ) => {

    const scope = SCOPES.find( s => s.code === scopeCode )
 
    if ( !scope ) return null
 
    return scope.id
 
 }
 
export const getUserScopeCodeFromId = ( scopeID ) => {
 
    const scope = SCOPES.find( s => s.id === scopeID )
 
    if ( !scope ) return null
 
    return scope.code
 
 }
  