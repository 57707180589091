import React, { useState } from 'react';

import NewEmpresa from './NewEmpresa';
import { Select } from 'antd';
import PAISES from '../../../../constantes/paises';

const { Option } = Select;

const Filter = ( props ) => {

  // VARIABLES DE ESTADO PARA MOSTRAR MODAL DE NUEVA ZONA Y FILTRO
  const [ newEmpresaFormVisible, setNewEmpresaFormVisible ] = useState( false );
  const [ filterString, setFilterString ] = useState( '' );

  // HANDLER PARA EL CAMBIO DE VALOR EN EL INPUT DE FILTRO
  const handleChange = ( event ) => {
    const value = event.target.value;
    setFilterString( value );

    props.filterChangeHandler( value );
  }

  // HANDLER PARA RELODEAR LISTA DE ZONAS AL CREAR UNA ZONA NUEVA
  const reloadEmpresas = () => {

    setNewEmpresaFormVisible( false );

    props.reloadEmpresas();

  }

  // RENDERIZADO DE COMPONENTE
  return (
    <div className="filter">
      <div className="selectors">
        <div className="search">
          <input name="user" placeholder='Search...' value={ filterString } onChange={ handleChange } />
        </div>
        {
          props.countryModifyable
          &&
          <div className="country">
          <Select value={ props.countryFiltered } onChange={ props.setCountryFiltered }>
            {
              PAISES.map( pais => <Option key={ pais.id } value={ pais.id }> { pais.name } </Option>)
            }
            <Option value="ALL"> Todos </Option>
          </Select>
        </div>
        }
      </div>
      <div className="actions">
        <div className="options">
          <div className="newEmpresa" onClick={ () => { setNewEmpresaFormVisible( true ) } }>
            +
          </div>
        </div>
        <NewEmpresa open={ newEmpresaFormVisible } handlerClose={ () => { setNewEmpresaFormVisible( false ) } } successHandler={ reloadEmpresas } countryModifyable={ props.countryModifyable } userdata={ props.userdata } />
      </div>

    </div>
  )
}

export default Filter
