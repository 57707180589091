import Api from './Api';
import authService from './Auth';

const usersService = {

  get: (  ) => {

    return Api.get( '/usuarios', {
      headers: {
        token: authService.getToken()
      }
    } )
  },
  post: ( jsonData, jsonCallbacks ) => {

      if ( jsonCallbacks ) if ( jsonCallbacks.beforeSend ) jsonCallbacks.beforeSend();

      return Api.post( '/usuario/registro', jsonData, {
        headers: {
          token: authService.getToken()
        }
      } ).then( data => {

        if ( jsonCallbacks ) if ( jsonCallbacks.success ) jsonCallbacks.success( data.data.info );
      } ).catch( ( data ) => {
        if ( jsonCallbacks ) if ( jsonCallbacks.error ) jsonCallbacks.error( data.response.data );
      } )

  },
  delete: ( usersID, jsonCallbacks ) => {

    return Api.delete( `/usuario?id=${ JSON.stringify( usersID ) }`, {
      headers: {
        token: authService.getToken()
      }
    } ).then( data => {

      if ( jsonCallbacks ) if ( jsonCallbacks.success ) jsonCallbacks.success( data.data.info );
    } ).catch( () => {
      if ( jsonCallbacks ) if ( jsonCallbacks.error ) jsonCallbacks.error();
    } )
  },
  getTypes: ( ) => {
    return Api.get( '/usuarios/tipos/alta', {
      headers: {
        token: authService.getToken()
      }
    } )
  }

}

export default usersService
