import Api from './Api';
import authService from './Auth';

const empresasService = {

  get: (  ) => {

    return Api.get( '/empresa', {
      headers: {
        token: authService.getToken()
      }
    } )
  },
  post: ( jsonData, jsonCallbacks ) => {

      if ( jsonCallbacks ) if ( jsonCallbacks.beforeSend ) jsonCallbacks.beforeSend();

      return Api.post( '/empresa', jsonData, {
        headers: {
          token: authService.getToken()
        }
      } ).then( data => {

        if ( jsonCallbacks ) if ( jsonCallbacks.success ) jsonCallbacks.success( data );
      } ).catch( () => {
        if ( jsonCallbacks ) if ( jsonCallbacks.error ) jsonCallbacks.error();
      } )

  },
  delete: ( empresaID, jsonCallbacks ) => {

    return Api.delete( `/empresa/${ empresaID }`, {
      headers: {
        token: authService.getToken()
      }
    } ).then( data => {

      if ( jsonCallbacks ) if ( jsonCallbacks.success ) jsonCallbacks.success( data.data.info );
    } ).catch( () => {
      if ( jsonCallbacks ) if ( jsonCallbacks.error ) jsonCallbacks.error();
    } )
  }

}

export default empresasService
