import React, { useState, useEffect } from 'react';

import empresasService from '../../../services/Empresas';

import Header from './components/Header';
import Filter from './components/Filter';
import EmpresasList from './components/EmpresasList';

import './empresasPage.scss';
import { getUserScopeCodeFromId } from '../../../constantes/funcionalidades';

const EmpresasPage = ( props ) => {

  const userScopeCode = getUserScopeCodeFromId( props.userdata.scope )
  const countryModifyable = userScopeCode === 'GLOBAL' || userScopeCode === 'SUDO'

  const [ filterString, setFilterString ] = useState( '' );
  const [ countryFiltered, setCountryFiltered ] = useState( userScopeCode === 'GLOBAL' || userScopeCode === 'SUDO' ? 'ALL' : ( userScopeCode === 'COUNTRY' ? props.userdata.pais : null ) );
  const [ loading, setLoading ] = useState( true );

  useEffect( () => {

    if ( !props.empresas ){
      empresasService.get()
        .then( response => {
          props.setEmpresas( response.data.info )
          setLoading( false )
        })
    } else {
      setLoading( false )
    }

  }, [])

  const reloadEmpresas = () => {
    setLoading( true )

    empresasService.get()
      .then( response => {
        props.setEmpresas( response.data.info )
        setLoading( false )
      })
  }

  // RENDERIADO DEL COMPONENTE
    // LOADING
    // COMPONENTE
  if ( loading ){
    return (
      <div className="loadingPage">
        <img src="/assets/img/icons/loading.svg" alt="loading"></img>
      </div>
    )
  } else {

    return (
      <div className="empresasPage">
        <Header />
        <Filter filterChangeHandler={ setFilterString } reloadEmpresas={ reloadEmpresas } countryFiltered={ countryFiltered } setCountryFiltered={ setCountryFiltered } countryModifyable={ countryModifyable } userdata={ props.userdata } />
        <EmpresasList empresas={ props.empresas } filterString={ filterString } countryFiltered={ countryFiltered } reloadEmpresas={ reloadEmpresas } />
      </div>
    )

  }

}

export default EmpresasPage
