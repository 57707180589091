import Api from './Api';

const authService = {
  isAuthenticated: () => {
    if ( window.localStorage.getItem('ta') ){
      return true
    } else {
      return false
    }
  },
  getToken: () => {
    return window.localStorage.getItem('ta');
  },
  getUserData: (  ) => {

    return Api.get( '/usuario/self', {
      headers: {
        token: authService.getToken()
      }
    } )

  },
  updateLocale: ( newLocale ) => {
    return Api.put( '/usuario', { idioma: newLocale },{
      headers: {
        token: authService.getToken()
      }
    } )
  },
  updatePassword: ( passwordData ) => {
    return Api.post( '/usuario/password/cambio', passwordData, {
      headers: {
        token: authService.getToken()
      }
    })
  },
  updatePasswordOutside: ( passwordData ) => {
    return Api.post( '/usuario/password/cambio/outside', passwordData)
  },
  recoveryPasswordPetition: ( emailData ) => {
    return Api.post( '/usuario/password/recuperar', emailData)
  },
  ping: ( jsonCallbacks ) => {

    return Api.get('/ping', {
      headers: {
        token: authService.getToken()
      }
    }).then( data => {
      window.localStorage.setItem('ta', data.data.token );

      if ( jsonCallbacks ) if ( jsonCallbacks.success ) jsonCallbacks.success();
    } ).catch( () => {
      if ( jsonCallbacks ) if ( jsonCallbacks.error ) jsonCallbacks.error();
    } )

  },
  sudo: ( jsonData, jsonCallbacks ) => {

    return Api.post('/sudo', jsonData, {
      headers: {
        token: authService.getToken()
      }
    } )

  }
};

// i18n.changeLanguage( data.info.idioma );

export default authService
