import React from 'react';
import { useHistory } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import loginService from '../../../../services/Login';

const AccountCard = ( props ) => {

  const history = useHistory();

  return (
    <div className="card full">
      <div className="title"> Account </div>
      <div className="data">
        <div className="icon">
          <img src="/assets/img/icons/user.svg" alt="profile" />
        </div>
        <div className="dataValues">
          <div className="column">
            <div className="dataItem">
              <div className="tag"> First Name </div>
              <div className="value"> { props.user.nombre } </div>
            </div>
            <div className="dataItem">
              <div className="tag"> Last Name </div>
              <div className="value"> { props.user.apellido } </div>
            </div>
            <div className="dataItem">
              <div className="tag"> Email </div>
              <div className="value"> { props.user.email } </div>
            </div>
          </div>
        </div>
      </div>
      <div className="actions">
        <Button variant="danger" onClick={ () => { loginService.logout(() => history.push("/login")); } }> Log out </Button>
      </div>
    </div>
  )
}

export default AccountCard
