import React from 'react'

import Header from './components/Header';
import FeaturesList from './components/FeaturesList';

import './sidenav.scss';

const Sidenav = ( props ) => {

  return (
    <div className='sidenav'>
      <Header />
      <FeaturesList setWindow={ props.setWindow } window={ props.window } />
    </div>
  )
}

export default Sidenav
