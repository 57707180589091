import React, { useState, useEffect } from 'react';

import Header from './components/Header';
import Filter from './components/Filter';
import UserList from './components/UserList';

import usersService from '../../../services/Users';

import './userPage.scss';

const UserPage = ( props ) => {

  // VARIABLES DE ESTADO DEL FILTRO, LOADING Y LISTA DE USUARIOS
  const [ filterString, setFilterString ] = useState( '' );
  const [ loading, setLoading ] = useState( true );

  useEffect( () => {

    if ( !props.usuarios ){

      setLoading( false )

      usersService.get()
        .then( response => {
          props.setUsuarios( response.data.info )
        })

    } else {
      setLoading( false )
    }

  }, [ props.usuarios ])

  // FUNCION HANDLER PARA RELODEAR EL COMPONENTE
  const reloadUsers = () => {

    setLoading( true )

    usersService.get()
      .then( response => {
        props.setUsuarios( response.data.info )
        setLoading( false )
      })
  }

  // RENDERIADO DEL COMPONENTE
    // LOADING
    // COMPONENTE
  if ( loading || !props.usuarios ){
    return (
      <div className="loadingPage">
        <img src="/assets/img/icons/loading.svg" alt="loading"></img>
      </div>
    )
  } else {
    return (
      <div className="userPage">
        <Header />
        <Filter filterChangeHandler={ setFilterString } reloadUsers={ reloadUsers } empresas={ props.empresas } setEmpresas={ props.setEmpresas } userdata={ props.userdata } />
        <UserList users={ props.usuarios } filterString={ filterString } reloadUsers={ reloadUsers } empresas={ props.empresas } />
      </div>
    )
  }

}

export default UserPage
