import React from 'react';
import { IconContext } from "react-icons";
import { BsFileEarmark } from "react-icons/bs";

import Button from 'react-bootstrap/Button';

import env from '../../../../environment/environment';
import authService from '../../../../services/Auth';

const Header = ( props ) => {

  const getExcel = () => {
    
    var f = document.getElementById('excelListForms');
    f.token.value = authService.getToken();
    f.forms.value = JSON.stringify( props.formularios.map( f => f._id ) );
    window.open('', 'TheWindow');
    f.submit();

  }

  return (
    <div className="header">
      <div className="title"> Forms List </div>
      <div className="separator"> | </div>
      <div className="icon">
        <IconContext.Provider size={ 25 } value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
          <div>
            <BsFileEarmark />
          </div>
        </IconContext.Provider>
      </div>

      {
        props.formularios.length
        ?
        <div className="btn-group">
          <Button variant="outline-primary" onClick={ getExcel }> Download Excel </Button>
          <form id="excelListForms" method="post" action={ env.url + '/files/excel' } target="TheWindow">
            <input type="hidden" name="forms" value="content" />
            <input type="hidden" name="token" value="content" />
          </form>
        </div>
        :
        null
      }
    </div>
  )
}

export default Header
