import React, { useState } from 'react';

import EmpresasPage from './pages/empresas/EmpresasPage';
import UserPage from './pages/users/UserPage';
import FormulariosPage from './pages/formularios/FormulariosPage';
import ProfilePage from './pages/profile/ProfilePage';

const Main = ( props ) => {

  const [ empresas, setEmpresas ] = useState( null )
  const [ usuarios, setUsuarios ] = useState( null )
  const [ formularios, setFormularios ] = useState( null )

  return (
     <div className="pageContainer">
      { props.window === 'EMPRESAS' ? <EmpresasPage empresas={ empresas } setEmpresas={ setEmpresas } userdata={ props.userdata } /> : null }
      { props.window === 'USUARIOS' ? <UserPage usuarios={ usuarios } setUsuarios={ setUsuarios } empresas={ empresas } setEmpresas={ setEmpresas } userdata={ props.userdata } /> : null }
      { props.window === 'FORMULARIOS' ? <FormulariosPage formularios={ formularios } setFormularios={ setFormularios } /> : null }
      { props.window === 'PERFIL' ? <ProfilePage profile={ props.userdata } /> : null }
     </div>
  )

}

export default Main
