import React, { useState, useEffect } from 'react';

import formsService from '../../../services/Forms';

import Header from './components/Header';
import FormulariosList from './components/FormulariosList';
import NoForms from './components/NoForms';

import './formulariosPage.scss';

const EmpresasPage = ( props ) => {

  const [ loading, setLoading ] = useState( true );

  useEffect( () => {

    formsService.get()
      .then( response => {
        props.setFormularios( response.data.info )
        setLoading( false )
      })

  }, [])

  const reloadEmpresas = () => {
    setLoading( true )

    formsService.get()
      .then( response => {
        props.setFormularios( response.data.info )
        setLoading( false )
      })
  }

  // RENDERIADO DEL COMPONENTE
    // LOADING
    // COMPONENTE
  if ( loading ){
    return (
      <div className="loadingPage">
        <img src="/assets/img/icons/loading.svg" alt="loading"></img>
      </div>
    )
  } else {
    return (
      <div className="formsPage">
        <Header formularios={ props.formularios } />
        {
          props.formularios.length
          ?
          <FormulariosList formularios={ props.formularios } />
          :
          <NoForms  />
        }
      </div>
    )
  }

}

export default EmpresasPage
