import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';

import usersService from '../../../../services/Users';
import empresasService from '../../../../services/Empresas';

import { IconContext } from "react-icons";
import { FiUserPlus } from "react-icons/fi";

import Swal from 'sweetalert2'
import { Select, Switch } from 'antd';
import PAISES from '../../../../constantes/paises';
import { getUserScopeCodeFromId } from '../../../../constantes/funcionalidades';

const { Option } = Select;

// ESTILO DEL MODAL DE INVITACION DE USUARIO
const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    background            : 'white'
  }
};

const NewUser = ( props ) => {

  const [ tipoUsuario, setTipoUsuario ] = useState( 1 );
  const [ pais, setPais ] = useState( 'ARGENTINA' );
  const [ empresa, setEmpresa ] = useState( null );
  const [ loading, setLoading ] = useState( true );
  const [ tiposUsuariosOpciones, setTiposUsuariosOpciones ] = useState( null )
  const [ showCompanies, setShowCompanies ] = useState( true )
  const [ passwordSeateable, setPasswordSeteable ] = useState( false )

  useEffect( () => {

    if ( props.open ){
      setEmpresa( props.empresas[0]._id )

      setLoading( true )

      usersService.getTypes().then( res => {
        setTiposUsuariosOpciones( res.data.info )

        setLoading( false )
      } )
    }

  }, [ props.open ] )

  useEffect( () => {

    if ( tiposUsuariosOpciones && tiposUsuariosOpciones.length ){

      const tipoUsuarioMatched = tiposUsuariosOpciones.find( tu => tu.id === tipoUsuario )

      if ( tipoUsuarioMatched ){
        
        const tipoUsuarioScope = getUserScopeCodeFromId( tipoUsuarioMatched.scope )
        if ( tipoUsuarioScope === 'COMPANY' || tipoUsuarioScope === 'SELF' ){
          setShowCompanies( true )
        } else {
          setShowCompanies( false )
        }

      }

    }

  }, [ tipoUsuario, tiposUsuariosOpciones ] )

  const handleTipoUsuarioChange = newtipoUsuario => {
    setTipoUsuario( newtipoUsuario );
  }

  const handlePaisChange = newPais => {
    setPais( newPais );
  }

  const handleEmpresaChange = newEmpresa => {
    setEmpresa( newEmpresa )
  }

  // FUNCION DE CREACION DE ZONA
  const inviteUser = ( ) => {

    const name = document.getElementById('invitationName').value;
    const lastName = document.getElementById('invitationLastName').value;
    const email = document.getElementById('invitationEmail').value;
    const password = document.getElementById('invitationPassword').value;

    if ( !name || !lastName || !email ){
      Swal.fire({
        icon: 'warning',
        title: 'Incompleted Fields!',
        text: 'Please complete all data fields to send invitation.'
      })

      return;
    }

    // ALERT DE LOADING
    Swal.fire ({
       title: '..Sending invitation to this new user..',
       onBeforeOpen: () => {
         Swal.showLoading ()
       }
    })

    // CAPTURO LOS DATOS PARA ENVIAR A LA API POST /ZONES
    const jsonData = {
      nombre: name,
      apellido: lastName,
      email: email,
      tipoUsuario: tipoUsuario,
      pais: pais,
      empresa: showCompanies ? empresa : undefined,
      password: passwordSeateable ? password : undefined
    }

    // LE PEGO A LA API Y AL FINALIZAR LLAMO AL HANDLER DE SUCCESS Y CIERRO EL ALERT
    usersService.post( jsonData, {
      success: ( res ) => {
        Swal.close();
        props.successHandler();

        // if ( !res.data.ok ){
          
        //   if ( res.data.code === 1 ){
        //     Swal.fire({
        //       icon: 'error',
        //       title: 'Sin permisos',
        //       text: 'No tienes los permisos necesarios para dar de alta un usuario.'
        //     })
        //   }

        // } else {
        //   props.successHandler();
        // }

      },
      error: err => {

        Swal.close();

        if ( err.code && err.code === 1 ) {
          Swal.fire({
            icon: 'error',
            title: 'Existing User',
            text: 'This user already has an account in the system.'
          })
        }
      }
    } )

  }

  // RENDERIZADO DEL COMPONENTE MODAL
  return (
    <div>
      <Modal
              isOpen={ props.open }
              onRequestClose={ props.handlerClose }
              style={ customStyles }
              contentLabel="Example Modal"
              appElement={ document.getElementById('root') }
            >

        {
          loading
          ?
          <div className="modalContainer newUser">
            <img src="/assets/img/icons/loading.svg" alt="loading"></img>
          </div>
          :
          <div className="modalContainer newUser">
            <div className="column" style={{ "fontSize": '100px' }}>
              <IconContext.Provider value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
                <div>
                  <FiUserPlus />
                </div>
              </IconContext.Provider>
            </div>
            <div className="data">
              <div className="form">
                <div className="line name">
                  <div className="form-input">
                    <input id="invitationName" type="text" name="firstname" placeholder='First Name' />
                  </div>
                  <div className="form-input">
                    <input id="invitationLastName" type="text" name="lastname" placeholder='Last Name' />
                  </div>
                </div>
                <div className="line password">
                  <div className="form-input">
                    <input disabled={ !passwordSeateable } id="invitationPassword" type="text" name="password" placeholder='Password' />
                  </div>
                  <Switch checked={ passwordSeateable } onChange={ setPasswordSeteable } />
                </div>
                <div className="line email">
                  <div className="form-input">
                    <input id="invitationEmail" type="text" name="user" placeholder='Email' />
                  </div>
                </div>
                <div className="line extra">
                  <div className="form-select" style={{ width: "100%" }}>
                    {
                      tiposUsuariosOpciones && tiposUsuariosOpciones.length
                      &&
                      <Select value={ tipoUsuario } style={{ width: "100%" }} onChange={ handleTipoUsuarioChange }>
                        {
                          tiposUsuariosOpciones.map( tu => <Option key={ tu.id } value={ tu.id }> { tu.name } </Option>)
                        }
                      </Select>
                    }
                  </div>
                </div>
                <div className="line pais">
                  <div className="form-select" style={{ width: "100%" }}> 
                      <Select value={ pais } style={{ width: "100%" }} onChange={ handlePaisChange }>
                        {
                          PAISES.map( pais => <Option key={ pais.id } value={ pais.id }> { pais.name } </Option> )
                        }
                      </Select>
                    </div>
                </div>
                {
                  showCompanies
                  &&
                  <div className="line empresas">
                    <div className="form-select" style={{ width: "100%" }}>
                      <Select defaultValue={ empresa } style={{ width: "100%" }} onChange={ handleEmpresaChange }>
                        {
                          props.empresas.map( emp => {
                          return (
                            <Option key={ emp._id } value={ emp._id }> { emp.nombre } </Option>
                          )
                        } )
                      }
                      </Select>
                    </div>
                  </div>
                }
              </div>
              <div className="footer">
                <Button variant="primary" onClick={ inviteUser }> Send Invitation </Button>
              </div>
            </div>
          </div>
        }

      </Modal>
    </div>
  )
}

export default NewUser
