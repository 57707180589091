import React from 'react';
import { IconContext } from "react-icons";
import { BiBuildingHouse } from "react-icons/bi";

const Header = ( props ) => {
  return (
    <div className="header">
      <div className="title"> Companies List </div>
      <div className="separator"> | </div>
      <div className="icon">
        <IconContext.Provider size={ 25 } value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
          <div>
            <BiBuildingHouse />
          </div>
        </IconContext.Provider>
      </div>
    </div>
  )
}

export default Header
