const env = {

  production: false,
  // front_url: 'http://localhost:3002',
  // url: 'http://localhost:3000'
  url: 'https://api.hubvetanco.com',
  front_url: 'https://hubvetanco.com',
  maintenance: false
}

export default env
