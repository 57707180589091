import React, { useState, useEffect } from 'react';

import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button';

import usersService from '../../../../services/Users';

import { IconContext } from "react-icons";
import { BiTrashAlt } from "react-icons/bi";
import { HiOutlinePencil } from "react-icons/hi";
import { FiLogIn } from "react-icons/fi";
import { FaExclamation } from "react-icons/fa";
import { funcionalidades } from '../../../../constantes/funcionalidades';

// COMPONENTS

// ITEM LIST HEADER
const UserListItemHeader = () => {

  return (
    <div className="userListItem header">
      <div className="company"> Company </div>
      <div className="name"> Name </div>
      <div className="email"> Email </div>
      <div className="role"> Role </div>
      <div className="country"> Country </div>
      <div className="actions">
        <IconContext.Provider size={ 25 } value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
          <div>
            <BiTrashAlt />
          </div>
        </IconContext.Provider>
      </div>
    </div>
  )
}

// ITEM LIST DEL BODY
const UserListItem = ( props ) => {

  return (
    <div className="userListItem">
      <div className="company"> { props.empresa && props.empresa.nombre } </div>
      <div className="name"> { props.name } </div>
      <div className="email"> { props.email } </div>
      <div className="role"> { props.tipoUsuarioTag } </div>
      <div className="country"> { props.pais } </div>
      <div className="actions">
        <div className="delete" onClick={ () => deleteUser( [ props.userID ], props.reloadUsers ) }>
          <IconContext.Provider size={ 25 } value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
            <div>
              <BiTrashAlt />
            </div>
          </IconContext.Provider>
        </div>
      </div>
    </div>
  )
}

// LISTA COMPLETA DE USUARIOS
const UserList = ( props ) => {

  // VARIABLES DE ESTADO DE PAGINACION Y FILTRO
  const [ filteredList, setFilteredList ] = useState( [] );

  // HOOK PARA ACTUALIZACION DE LISTA DE USUARIOS EN FUNCION DEL FILTRO
  useEffect( () => {
    const fullList = props.users.map( user => {

      const fullName = `${ user.nombre } ${ user.apellido }`

      if ( fullName.toUpperCase().includes( props.filterString.toUpperCase() ) ){
        return <UserListItem 
                            key={ user._id } 
                            userID={ user._id } 
                            name={ fullName } 
                            email={ user.email } 
                            tipoUsuario={ user.tipoUsuario } 
                            tipoUsuarioTag={ user.tipoUsuarioTag }
                            empresa={ user.empresa }
                            pais={ user.pais } 
                            reloadUsers={ props.reloadUsers } />
      }

    } ).filter( data => data )

    setFilteredList( fullList );

  }, [ props.filterString ] )


  // RENDERIZO LA LISTA DE ZONAS CON LA PAGINACION
  return (
    <div className="userList">
      {
        props.users.length
        ?
        <div className="list">
          <UserListItemHeader />
          { filteredList }
        </div>
        :
        (
          !props.empresas.length
          ?
          <div className="noCompanies">
            <div className="logo">
              <IconContext.Provider value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
                <div>
                  <FaExclamation />
                </div>
              </IconContext.Provider>
            </div>
            <div className="title"> ¡ Sin Empresas ! </div>
            <div className="message">
              <p> Todavía no creaste ninguna empresa. </p>
              <p> Para dar de alta usuarios necesitas crear una empresa para ubicarlo previamente. </p>
            </div>
            <div className="action">
              <Button variant="success" onClick={ () => { document.querySelector( '.sidenav .featuresList .featureItem.empresas' ).click() } }> Ir a Empresas </Button>
            </div>
          </div>
          :
          <div className="noUsers">
            <div className="logo">
              <IconContext.Provider value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
                <div>
                  <FaExclamation />
                </div>
              </IconContext.Provider>
            </div>
            <div className="title"> ¡ Sin Usuarios ! </div>
            <div className="message">
              <p> Todavía no creaste ningun usuario. </p>
              <p> Podrás dar de alta todos los usuarios en esta sección. </p>
            </div>
            <div className="action">
              <Button variant="success" onClick={ () => { document.querySelector( '.userPage .newUser' ).click() } }> Agregar Usuario </Button>
            </div>
          </div>
        )
      }
    </div>
  )
}

// FUNCTIONS

// BAJA DE USUARIO
const deleteUser = ( usersID, reloadUsers, t ) => {
  // DISPARO ALERT DE CONFIRMACION
  Swal.fire({
            title: 'Delete User',
            text: 'Are you sure you want to delete this user?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes, delete it!',
            showLoaderOnConfirm: true,
            // AL CONFIRMAR LE PEGO A LA API DE DELETE /ZONES
            preConfirm: ( value ) => {
              return usersService.delete( usersID )
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            // AL FINALIZAR MUESTRO ALERT DE SUCCESS Y LE PEGO AL HANDLER DE RELOAD PAGE
            if ( result.value ) {
              Swal.fire(
                'Deleted!',
                'User has been deleted successfully.',
                'success'
              )

              reloadUsers();
            }
          })
}

export default UserList
