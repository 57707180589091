import React from 'react';
import { useHistory } from "react-router-dom";

import { IconContext } from "react-icons";
import { BiBuildingHouse, BiLogOut } from "react-icons/bi";
import { FiUser, FiUsers } from "react-icons/fi";
import { BsFileEarmark } from "react-icons/bs";

import loginService from '../../../services/Login';

const FeaturesList = ( props ) => {

  const history = useHistory();

  return (
    <div className="featuresList">
      <div className="subtitle"> Sections </div>
        <div className={ props.window === 'EMPRESAS' ? "featureItem empresas selected" : "featureItem empresas" } onClick={ () => { props.setWindow( 'EMPRESAS' ) } } >
          <div className="logo">
            <IconContext.Provider value={{ color: "white", className: "global-class-name" }}>
              <div>
                <BiBuildingHouse />
              </div>
            </IconContext.Provider>
          </div>
          <div className="tag"> Empresas </div>
        </div>
        <div className={ props.window === 'USUARIOS' ? "featureItem selected" : "featureItem" } onClick={ () => { props.setWindow( 'USUARIOS' ) } } >
          <div className="logo">
            <IconContext.Provider value={{ color: "white", className: "global-class-name" }}>
              <div>
                <FiUsers />
              </div>
            </IconContext.Provider>
          </div>
          <div className="tag"> Usuarios </div>
        </div>
        <div className={ props.window === 'FORMULARIOS' ? "featureItem selected" : "featureItem" } onClick={ () => { props.setWindow( 'FORMULARIOS' ) } } >
          <div className="logo">
            <IconContext.Provider value={{ color: "white", className: "global-class-name" }}>
              <div>
                <BsFileEarmark />
              </div>
            </IconContext.Provider>
          </div>
          <div className="tag"> Formularios </div>
        </div>
        <div className={ props.window === 'PERFIL' ? "featureItem selected" : "featureItem" } onClick={ () => { props.setWindow( 'PERFIL' ) } }>
          <div className="logo">
          <IconContext.Provider value={{ color: "white", className: "global-class-name" }}>
            <div>
              <FiUser />
            </div>
          </IconContext.Provider>
          </div>
          <div className="tag"> Perfil </div>
        </div>
    </div>
  )
}

export default FeaturesList;
