import React, { useState } from 'react';
import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';

import { IconContext } from "react-icons";
import { BiBuildingHouse } from "react-icons/bi";

import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import empresasService from '../../../../services/Empresas';

import Swal from 'sweetalert2'
import { Select } from 'antd';
import PAISES from '../../../../constantes/paises';



const { Option } = Select;

// ESTILO DEL MODAL DE INVITACION DE USUARIO
const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    background            : 'white'
  }
};

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

const NewEmpresa = ( props ) => {

  const [ imageURL, setImageURL ] = useState( null )
  const [ loading, setLoading ] = useState( false )
  const [ country, setCountry ] = useState( props.userdata.pais )

  // FUNCION DE CREACION DE ZONA
  const createCompany = () => {

    const name = document.getElementById('companyName').value;

    if ( !name  ){
      Swal.fire({
        icon: 'warning',
        title: 'Incompleted Fields!',
        text: 'Please complete all data fields to create the company.'
      })

      return;
    }

    // ALERT DE LOADING
    Swal.fire ({
       title: '..Creating the company..',
       onBeforeOpen: () => {
         Swal.showLoading ()
       }
    })

    // CAPTURO LOS DATOS PARA ENVIAR A LA API POST /ZONES
    const jsonData = {
      nombre: name,
      pais: country,
      logo: imageURL && imageURL.split( 'base64,' )[ 1 ] ? imageURL.split( 'base64,' )[ 1 ] : null
    }

    // LE PEGO A LA API Y AL FINALIZAR LLAMO AL HANDLER DE SUCCESS Y CIERRO EL ALERT
    empresasService.post( jsonData, {
      success: res => {
        Swal.close();

        if ( !res.data.ok ){
          
          if ( res.data.code === 1 ){
            Swal.fire({
              icon: 'error',
              title: 'Sin permisos',
              text: 'No tienes los permisos necesarios para dar de alta una empresa.'
            })
          }

        } else {
          props.successHandler();
        }

      }
    } )

  }

  const handleChange = info => {

    getBase64(info.file.originFileObj, imageUrl => {
      setImageURL( imageUrl )
      setLoading( false )
    });
  };

  const uploadButton = (
    <div>
      { loading ? <LoadingOutlined /> : <PlusOutlined /> }
      <div style={{ marginTop: 8 }}> Subir Logo </div>
    </div>
  );

  // RENDERIZADO DEL COMPONENTE MODAL
  return (
    <div>
      <Modal
              isOpen={ props.open }
              onRequestClose={ props.handlerClose }
              style={ customStyles }
              contentLabel="Example Modal"
              appElement={ document.getElementById('root') }
            >

        <div className="modalContainer newEmpresa">
          <div className="data">
            <div className="form">
              <div className="line name">
                <div className="form-input">
                  <input id="companyName" type="text" name="company" placeholder='Company Name' />
                </div>
              </div>
              {
                props.countryModifyable
                &&
                <div className="line country">
                  <div className="form-select">
                    <Select value={ country } style={{ width: '180px' }} onChange={ setCountry }>
                      {
                        PAISES.map( pais => <Option key={ pais.id } value={ pais.id }> { pais.name } </Option> )
                      }
                    </Select>
                  </div>
              </div>
              }
              <div className="image">
                <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                  >
                    {imageURL ? <img src={imageURL} alt="avatar" style={{ width: '100%', height: '100%' }} /> : uploadButton}
                </Upload>
                <div className="tag">
                  *Te recomendamos subir un logo de tamaño 150x100px
                </div>
              </div>
            </div>
            <div className="footer">
              <Button variant="primary" onClick={ createCompany }> Create Company </Button>
            </div>
          </div>
        </div>

      </Modal>
    </div>
  )
}

export default NewEmpresa
