import React from 'react';

// COMPONENTS
const getIndexStyle = ( value ) => {
  let color, background;
  if ( value < 40 ){
    background = 'rgba(217, 83, 79, 0.8)'
    color = 'white'
  } else if ( value < 70 ){
    background = 'rgba(240, 173, 78, 0.8)'
    color = 'black'
  } else {
    background = 'rgba(92, 184, 92, 0.8)'
    color = 'white'
  }

  return {
    background: background,
    color: color
  }
}

// ITEM LIST HEADER
const FormularioListItemHeader = () => {

  return (
    <div className="formularioListItem header">
      <div className="datoInfo date"> Date </div>
      <div className="datoInfo company"> Company </div>
      <div className="datoInfo user"> User </div>
      <div className="datoInfo farm"> Farm </div>
      <div className="datoInfo galpon"> Galpon </div>
      <div className="datoInfo chickens"> Chickens </div>
      <div className="datoInfo age"> Age </div>

      <div className="datoResultado"> Calidad Intestinal </div>
      <div className="datoResultado"> Sistema Digestivo </div>
      <div className="datoResultado"> Grado Coccidios </div>
      <div className="datoResultado"> Grado Clostridios </div>

      <div className="datoPromedio"> Cavidad Bucal </div>
      <div className="datoPromedio"> Proventriculo </div>
      <div className="datoPromedio"> Molleja </div>
      <div className="datoPromedio"> Higado </div>
      <div className="datoPromedio"> Pancreas </div>
      <div className="datoPromedio"> Int. Delgado </div>
      <div className="datoPromedio"> Int. Grueso </div>
      <div className="datoPromedio"> Ciegos </div>
      <div className="datoPromedio"> Contenido </div>
      <div className="datoPromedio"> Tono </div>
      <div className="datoPromedio"> Enteritis Necrotica </div>
      <div className="datoPromedio"> Retencion de Yema </div>
      <div className="datoPromedio"> Presencia de Parasitos </div>
      <div className="datoPromedio"> E. acervulina </div>
      <div className="datoPromedio"> E. maxima </div>
      <div className="datoPromedio"> E. tenella </div>

      <div className="datoInfo"> Vacunacion </div>
      <div className="datoInfo"> Promotor Inicial </div>
      <div className="datoInfo"> Promotor Final </div>
      <div className="datoInfo"> Coccidrato Inicial </div>
      <div className="datoInfo"> Coccidrato Final </div>

    </div>
  )
}

// ITEM LIST DEL BODY
const FormularioListItem = ( props ) => {

  return (
    <div className="formularioListItem" key={ props.form._id }>
      <div className="datoInfo date"> { convertDateISO( props.form.datosFormulario.fecha ) } </div>
      <div className="datoInfo company"> { props.form.datosFormulario.usuario.empresa.nombre } </div>
      <div className="datoInfo user"> { props.form.datosFormulario.usuario.nombre + ' ' + props.form.datosFormulario.usuario.apellido } </div>
      <div className="datoInfo farm"> { props.form.datosFormulario.granja.nombre } </div>
      <div className="datoInfo galpon"> { props.form.datosFormulario.galpon } </div>
      <div className="datoInfo chickens"> { props.form.cantidadAves } </div>
      <div className="datoInfo age"> { props.form.datosFormulario.edad } </div>

      <div className="datoResultado" > <div className="container" style={ getIndexStyle( props.form.resultadosIndices.calidadIntestinal ) }>{ props.form.resultadosIndices.calidadIntestinal.toFixed(0) }%</div> </div>
      <div className="datoResultado"> { props.form.resultadosIndices.lesionesSistemaDigestivo.toFixed(0) }% </div>
      <div className="datoResultado"> { props.form.resultadosIndices.riesgoDeCoccidiosis.toFixed(0) }% </div>
      <div className="datoResultado"> { props.form.resultadosIndices.riesgoDeClostridiosis.toFixed(0) }% </div>

      <div className="datoPromedio"> { Math.round( props.form.promedios.digestivoCavidadBucal ) } </div>
      <div className="datoPromedio"> { Math.round( props.form.promedios.digestivoProventriculo ) } </div>
      <div className="datoPromedio"> { Math.round( props.form.promedios.digestivoMolleja ) } </div>
      <div className="datoPromedio"> { Math.round( props.form.promedios.digestivoHigado ) } </div>
      <div className="datoPromedio"> { Math.round( props.form.promedios.digestivoPancreas ) } </div>
      <div className="datoPromedio"> { Math.round( props.form.promedios.macroscopiaIntestinalIntestinoDelgado ) } </div>
      <div className="datoPromedio"> { Math.round( props.form.promedios.macroscopiaIntestinalIntestinoGrueso ) } </div>
      <div className="datoPromedio"> { Math.round( props.form.promedios.macroscopiaIntestinalCiegos ) } </div>
      <div className="datoPromedio"> { Math.round( props.form.promedios.macroscopiaIntestinalContenido ) } </div>
      <div className="datoPromedio"> { Math.round( props.form.promedios.macroscopiaIntestinalTono ) } </div>
      <div className="datoPromedio"> { Math.round( props.form.promedios.macroscopiaIntestinalEnteritisNecrotica ) } </div>
      <div className="datoPromedio"> { Math.round( props.form.promedios.macroscopiaIntestinalRetencionYema ) } </div>
      <div className="datoPromedio"> { Math.round( props.form.promedios.macroscopiaIntestinalPresenciaParasitos ) } </div>
      <div className="datoPromedio"> { Math.round( props.form.promedios.microscopiaIntestinalIntestinoDelgado ) } </div>
      <div className="datoPromedio"> { Math.round( props.form.promedios.microscopiaIntestinalIntestinoGrueso ) } </div>
      <div className="datoPromedio"> { Math.round( props.form.promedios.microscopiaIntestinalCiegos ) } </div>

      <div className="datoInfo"> { props.form.datosFormulario.vacunacion } </div>
      <div className="datoInfo"> { props.form.datosFormulario.promotorInicial?.nombre } </div>
      <div className="datoInfo"> { props.form.datosFormulario.promotorFinal?.nombre } </div>
      <div className="datoInfo"> { props.form.datosFormulario.coccidratoInicial?.nombre } </div>
      <div className="datoInfo"> { props.form.datosFormulario.coccidratoFinal?.nombre } </div>
    </div>
  )
}

// LISTA COMPLETA DE USUARIOS
const FormulariosList = ( props ) => {

  // RENDERIZO LA LISTA DE ZONAS CON LA PAGINACION
  return (
    <div className="formulariosList">
      <div className="list">
        <FormularioListItemHeader />
        { 
          props.formularios && props.formularios.length
          &&
          props.formularios.map( form => {
            return <FormularioListItem key={ form._id } form={ form } />
          } )
        }
      </div>
    </div>
  )
}

function convertDateISO( data_in ){
  const keyMonth = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December"
  }
	const fecha = new Date(Date.parse(data_in));
	const data_out = fecha.getDate() + " " + keyMonth[fecha.getMonth()+1].substr(0, 3)+" '"+fecha.getFullYear().toString().slice(-2);
	return data_out;
}

export default FormulariosList
