import Api from './Api';

const loginService = {

  login: ( jsonData, jsonCallbacks ) => {

    return Api.post('/login', { admin: true, ...jsonData } ).then( data => {
      window.localStorage.setItem('ta', data.data.token );

      if ( jsonCallbacks ) if ( jsonCallbacks.success ) jsonCallbacks.success();
    } ).catch( () => {
      if ( jsonCallbacks ) if ( jsonCallbacks.error ) jsonCallbacks.error();
    } )
  },
  logout: ( callback ) => {

    window.localStorage.removeItem('ta');
    if ( callback ) callback();

  }

}

export default loginService
