import React from 'react'
import { FaRegSadTear } from 'react-icons/fa'

import './maintenance.scss'

export const MaintenancePage = () => {
  return (
    <div className='page maintenance'>
        <div className='icon'>
            <FaRegSadTear />
        </div>
        <div className='title'>
            Perdón! Estamos en mantenimiento.
        </div>
        <div className='message'>
            Estaremos retomando el servicio a la brevedad.
        </div>
    </div>
  )
}
