import React from 'react';

import Button from 'react-bootstrap/Button';

import authService from '../../../../services/Auth';

import Swal from 'sweetalert2';

const PasswordCard = ( props ) => {

  const updatePassword = () => {

    const actualPassword = document.getElementById( 'actualPassword' ).value;
    const newPassword = document.getElementById( 'newPassword' ).value;
    const confirmNewPassword = document.getElementById( 'confirmNewPassword' ).value;

    if ( !actualPassword || !newPassword || !confirmNewPassword ){

      Swal.fire({
        icon: 'warning',
        title: 'Incompleted Fields!',
        text: 'Please complete all passwords to update it.'
      })

      return;
    }

    if ( newPassword !== confirmNewPassword ){

      Swal.fire({
        icon: 'warning',
        title: 'Uncompatible passwords!',
        text: 'Your new password and the confirmation password are uncompatible.' 
      })

      return;
    }

    if ( newPassword === actualPassword ){

      Swal.fire({
        icon: 'warning',
        title: 'Same Password!',
        text: 'Your new password and the new password cannot be the same.'
      })

      return;
    }


    Swal.fire ({
       title: '..Updating your password..',
       onBeforeOpen: () => {
         Swal.showLoading ()
       }
    })

    authService.updatePassword( {
      passwordActual: actualPassword,
      passwordNueva: newPassword
    } ).then( data => {
      Swal.fire({
        icon: 'success',
        title: 'Password Updated!',
        text: 'Your password has been changed successfully.'
      })

      document.getElementById( 'actualPassword' ).value = null;
      document.getElementById( 'newPassword' ).value = null;
      document.getElementById( 'confirmNewPassword' ).value = null;

    }, error => {
      Swal.fire({
        icon: 'error',
        title: 'Incorrect Password!',
        text: 'Your actual password is wrong.'
      })
    } )

  }

  return (
    <div className="card password">
      <div className="title"> Contraseña </div>
      <div className="content">
        <div className="column">
          <div className="line actualPassword">
            <div className="form-input">
              <input id="actualPassword" type="password" name="password" placeholder='Contraseña actual' />
            </div>
          </div>
        </div>
        <div className="column">
          <div className="line newPassword">
            <div className="form-input">
              <input id="newPassword" type="password" name="password" placeholder='Contraseña nueva' />
            </div>
          </div>
          <div className="line confirmNewPassword">
            <div className="form-input">
              <input id="confirmNewPassword" type="password" name="password" placeholder="Confirma la contraseña nueva" />
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <Button size="sm" onClick={ updatePassword }> Actualizar </Button>
      </div>
    </div>
  )
}


export default PasswordCard
