import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import environment from './environment/environment'

import './style/global.scss';

import Sidenav from './components/sidenav/Sidenav';
import Main from './components/Main';
import LoginPage from './components/pages/login/LoginPage';

import authService from './services/Auth';
import { MaintenancePage } from './components/pages/maintenance/MaintenancePage';

function PrivateRoute({ children, ...rest }) {

  const loggedIn = authService.isAuthenticated();

  return (
    <Route
      {...rest}
      render={({ location }) => {

        if ( loggedIn ) {
          return children
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        }
      } }
    />
  );
}

const App = () => {

  const [ window, setWindow ] = useState( 'EMPRESAS' )
  const [ loading, setLoading ] = useState( true )
  const [ userdata, setUserdata ] = useState( null )

  useEffect( () => {

    authService.getUserData( )
                              .then( response => {
                                setUserdata( response.data.info )
                                setLoading( false )
                              } )

  }, [] )

  if ( loading ){
    return ( 
      <div className="mainContainer">
        <div className="loadingPage">
          <img src="/assets/img/icons/loading.svg" alt="loading"></img>
        </div>
      </div>
    )
  } else {

    return (
      <div className="mainContainer">
        <Sidenav setWindow={ setWindow } window={ window }></Sidenav>
        <Main window={ window } userdata={ userdata }></Main>
      </div>
    )
    
  }

}

const InitContent = () => {

  if ( environment.maintenance ) return <MaintenancePage />

  return (
    <div className="App">
      <BrowserRouter basename="/">
        <Switch>
          <PrivateRoute path='/main' > <App /> </PrivateRoute>
          <Route path='/' exact> <Redirect to='/main' /> </Route>
          <Route path='/login'> <LoginPage /> </Route>
          <Route path='**'> <Redirect to="/login" /> </Route>
        </Switch>
      </BrowserRouter>
    </div>
  )
}

ReactDOM.render(
  <InitContent />,
  document.getElementById('root')
);
