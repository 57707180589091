import React from 'react';

const Header = ( props ) => {
  return (
    <div className="header">
      <div className="logo"> <img src='/assets/img/logo_vetanco_white.svg' alt="viip" /> </div>      
    </div>
  )
}

export default Header
