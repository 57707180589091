import Api from './Api';
import authService from './Auth';

const formsService = {

  get: (  ) => {

    return Api.get( '/formularios', {
      headers: {
        token: authService.getToken()
      }
    } )
  },
  photos: ( formID ) => {

    return Api.get( '/formulario/fotos/' + formID, {
      headers: {
        token: authService.getToken()
      }
    } )
  },
  delete: formID => {

    return Api.delete( '/formulario/' + formID, {
      headers: {
        token: authService.getToken()
      }
    } )
  }

}

export default formsService
