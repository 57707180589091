import React, { useState } from 'react';
import loginService from '../../../services/Login';
import authService from '../../../services/Auth';
import Swal from 'sweetalert2'

import { useHistory, useLocation } from "react-router-dom";

import './login.scss'

const LoginPage = () => {

  const [ passwordVisible, setPasswordVisible ] = useState( false );
  const [ flipped, setFlipped ] = useState( false );
  const history = useHistory();
  const location = useLocation();

  const { from } = location.state || { from: { pathname: "/main/dashboards" } };
  const login = () => {

    const userData = {
      email: document.getElementById('username').value,
      password: document.getElementById('password').value
    }

    loginService.login( userData, {
      success: () => {
        history.replace( from );
      },
      error: () => {
        Swal.fire({
          title: 'Login Error',
          text: 'Please check your email or password',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }
    });
  }

  const recovery = () => {

    const email = document.getElementById('mail').value
    console.log( email )

    authService.recoveryPasswordPetition({
      email: email
    })
    .then( res => {
      Swal.fire({
        title: 'Email Sent',
        text: 'Please check your email to update your password',
        icon: 'success',
        confirmButtonText: 'OK'
      })

      setFlipped( false )
    })
    .catch( err => {
      Swal.fire({
        title: 'Update Error',
        text: 'Oups! An error ocurred!',
        icon: 'error',
        confirmButtonText: 'OK'
      })
    })
  }

  return (
    <div className="loginContainer">

      <div className={ flipped ? "card-container flipped" : "card-container" }>
        <div className="card">
          <div className="card-side side-front">
            <div className="title">
                <img src="/assets/img/logo_vetanco.svg" alt="logo vetanco" />
            </div>
            <div className="form">

              <div id="username-field" className="field-wrapper input">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                  <input id="username" name="username" type="text" className="form-control" placeholder="Username" />
              </div>

              <div id="password-field" className="field-wrapper input">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
                  <input id="password" name="password" type={ passwordVisible ? 'text' : 'password' } className="form-control" placeholder="Password" />
              </div>

            </div>
            <div className="actions">
              <button onClick={ () => { setFlipped( true ) } } className="btn btn-null">Forgot Password?</button>
              <button onClick={ login } className="btn btn-primary">Log In</button>
            </div>
          </div>
          <div className="card-side side-back">
            <div className="title">
            <img src="/assets/img/logo_vetanco.svg" alt="logo vetanco" />
              </div>
              <div className="form">

                <div id="mail-field" className="field-wrapper input">
                    <svg class="emailSVG" enable-background="new 0 0 479.058 479.058" height="479.058" viewBox="0 0 479.058 479.058" width="479.058" xmlns="http://www.w3.org/2000/svg"><path d="m434.146 59.882h-389.234c-24.766 0-44.912 20.146-44.912 44.912v269.47c0 24.766 20.146 44.912 44.912 44.912h389.234c24.766 0 44.912-20.146 44.912-44.912v-269.47c0-24.766-20.146-44.912-44.912-44.912zm0 29.941c2.034 0 3.969.422 5.738 1.159l-200.355 173.649-200.356-173.649c1.769-.736 3.704-1.159 5.738-1.159zm0 299.411h-389.234c-8.26 0-14.971-6.71-14.971-14.971v-251.648l199.778 173.141c2.822 2.441 6.316 3.655 9.81 3.655s6.988-1.213 9.81-3.655l199.778-173.141v251.649c-.001 8.26-6.711 14.97-14.971 14.97z"/></svg>
                    <input id="mail" name="username" type="text" className="form-control" placeholder="Email" />
                </div>

              </div>
              <div className="actions">
                <button onClick={ () => { setFlipped( false ) } } className="btn btn-null">Log In</button>
                <button onClick={ recovery } className="btn btn-primary">Recovery Password</button>
              </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default LoginPage
